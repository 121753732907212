import { useState } from "react";
import { useRef } from "react";
import { supabase } from "../lib/api";

const TodoItem = ({ user, todo, onDelete }) => {
    const [isCompleted, setIsCompleted] = useState(todo.is_complete);
    const [todos, setTodos] = useState([]);
    const newTaskTextRef = useRef();
    const [setError] = useState("");

    const toggleCompleted = async () => {
        const { data, error } = await supabase
            .from("todos")
            .update({ is_complete: !isCompleted })
            .eq("id", todo.id)
            .single();
        if (error) {
            console.error(error);
        }
        setIsCompleted(data.is_complete);
    };

    const updateTodo = async () => {
        let taskText = newTaskTextRef.current.value;
        let task = taskText.trim();
        console.log(task);
        if (task.length <= 3) {
            setError("Task length should be more than 3!");
        } else {

        }
    };

    return (
        <div
            className={"p-3 max-h-14 flex align-center justify-between border"}
        >
            <span className={"truncate flex-grow"}>
                <input
                    className="cursor-pointer mr-2"
                    onChange={toggleCompleted}
                    type="checkbox"
                    checked={isCompleted ? true : ""}
                />
                <span
                    className={`w-full flex-grow ${
                        isCompleted ? "line-through" : ""
                    }`}
                >
                    {todo.task}
                </span>
            </span>
            
            <input
                    ref={newTaskTextRef}
                    type="text"
                    onKeyUp={(e) => e.key === "Enter" && updateTodo()}
                    className={
                        "bg-gray-200 border px-2 border-gray-300 w-full mr-4"
                    }
                />
                <button
                    onClick={updateTodo}
                    className={
                        "flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    }
                >
                    Update
                </button>
            <button
                className={"font-mono text-red-500 text-xl border px-2"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                }}
            >
                X
            </button>
        </div>
    );
};

export default TodoItem;
